import { PureComponent } from "react";

import { connect } from "react-redux";
import styled from "styled-components";
import Desktop from "constants/responsive/desktop/index";

const Button = styled.button`
  width: ${(props) =>
    props.width
      ? props.width
      : props.fullWidth
      ? "100%"
      : props.extraWide
      ? "50%"
      : "auto"};
  max-width: 26em;
  max-height: 2.5em;
  user-select: none;
  display: flex;
  align-items: center;
  animation: ${(props) => (props.disabled ? "none" : "fadeIn 0.5s")};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
  padding: 0.75em 1em !important;
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : "none"};
  margin: ${(props) =>
    props.margin ? props.margin : props.noMargin ? "0" : "8px"};
  font-size: 1em;
  font-weight: bold;
  pointer-events: all;
  border: none;
  outline: none;
  color: ${(props) => (props.color ? props.color : props.theme.white)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border-radius: 2em;
  transition: ${(props) => props.theme.defaultTransition};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : props.theme.subtleBoxShadow};
  background: ${(props) =>
    props.background
      ? props.background
      : props.dark
      ? props.theme.grey3
      : props.theme.grey4};
  fill: ${(props) => (props.disabled ? props.theme.grey5 : props.theme.grey2)};
  opacity: ${(props) => (props.disabled ? "0.4" : "1")};
  &:hover, &:focus {
    background: ${(props) =>
      props.backgroundHover
        ? props.backgroundHover
        : props.disabled
        ? props.dark
          ? props.theme.grey3
          : props.theme.grey4
        : props.theme.grey2};
    box-shadow: ${(props) => props.theme.sortOfSubtleBoxShadow};
  }
  &:active {
    transform: ${(props) => (props.disabled ? "none" : "scale(0.98)")};
    color: ${(props) =>
      props.disabled
        ? props.color
          ? props.color
          : props.theme.white
        : props.theme.grey2};
    background: ${(props) =>
      props.backgroundHover
        ? props.backgroundHover
        : props.disabled
        ? props.dark
          ? props.theme.grey3
          : props.theme.grey4
        : props.theme.veryLightGrey};
    box-shadow: ${(props) => props.theme.sortOfSubtleBoxShadow};
  }
`;
const Span = styled.span`
  margin-right: ${(props) =>
    props.iconMarginRight ? props.iconMarginRight : "0.5em"};
  display: flex;
  align-items: center;
`;

class AltCTA extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.handleClick({
      type: `BUTTON_CLICK_ALT_${(this.props.text || "")
        .toUpperCase()
        .split(" ")
        .join("_")}`,
    });
    if (this.props.clickAction) {
      this.props.clickAction();
    }
  }

  render() {
    return (
      <Button
        extraWide={this.props.extraWide}
        textTransform={this.props.textTransform}
        fullWidth={this.props.fullWidth}
        width={this.props.width}
        background={this.props.background}
        backgroundHover={this.props.backgroundHover}
        justifyContent={this.props.justifyContent}
        margin={this.props.margin}
        noMargin={this.props.noMargin}
        type={this.props.type}
        color={this.props.color}
        dark={this.props.dark}
        disabled={this.props.disabled}
        boxShadow={this.props.boxShadow}
        onClick={this.handleClick}
      >
        {this.props.icon ? (
          <Desktop
            content={
              <Span iconMarginRight={this.props.iconMarginRight}>
                {this.props.icon}
              </Span>
            }
          />
        ) : null}
        {this.props.text}
      </Button>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  handleClick: (arg) => dispatch({ type: arg.type, meta: arg.meta }),
});

export default connect(null, mapDispatchToProps)(AltCTA);
