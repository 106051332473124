import { createSelector } from "@reduxjs/toolkit";
var selectCurrentAPICall = function (state) { return state.fetchReducer.component; };
var selectCurrentAPICallFetching = function (state) { return state.fetchReducer.fetching; };
var selectCurrentAPICallError = function (state) { return state.fetchReducer.error; };
// Need to use selector factories to support reusing the selector across multiple components with different inputs
export var createIsFetchingSelector = function (componentToCheck) {
    return createSelector([selectCurrentAPICall, selectCurrentAPICallFetching], function (currentAPICall, fetching) { return currentAPICall === componentToCheck && fetching; });
};
export var createIsErrorSelector = function (componentToCheck) {
    return createSelector([selectCurrentAPICall, selectCurrentAPICallError], function (currentAPICall, error) { return currentAPICall === componentToCheck && !!error; });
};
export var createErrorSelector = function (componentToCheck) {
    return createSelector([selectCurrentAPICall, selectCurrentAPICallError], function (currentAPICall, error) { return currentAPICall === componentToCheck && !!error && error; });
};
export var createIsSuccessSelector = function (componentToCheck) {
    return createSelector([selectCurrentAPICall, selectCurrentAPICallFetching, selectCurrentAPICallError], function (currentAPICall, fetching, error) { return currentAPICall === componentToCheck && !fetching && error === false; });
};
export var createAPISelectors = function (componentToCheck) {
    return [
        createIsFetchingSelector(componentToCheck),
        createIsSuccessSelector(componentToCheck),
        createIsErrorSelector(componentToCheck),
        createErrorSelector(componentToCheck),
    ];
};
