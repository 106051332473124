import { Component } from "react";
import styled, { withTheme } from "styled-components";
import { FaTimes } from "react-icons/fa";
import { Img } from "constants/styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getImageSource } from "shared/helpers/image";

const Wrapper = styled.div`
  display: flex;
  width: ${({ stretch }) => stretch ? "100%" : "auto"};
  transition: ${({ theme }) => theme.fastTransition};
  margin: 0.3em;
  box-sizing: border-box;
  overflow: hidden;
  user-select: none;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ deletable, theme }) => deletable ? theme.deepRed : theme.grey4};
  box-shadow: ${({ theme }) => `${theme.smallBoxShadow} ${theme.lightestGrey}`};
  border-radius: 1em;
  cursor: ${({ cursor, staticPill }) => cursor || staticPill ? "auto" : "pointer"};
  background-color: ${({ deletable, theme }) => deletable ? theme.pastelYellow : theme.white};
  a {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  div {
    color: ${({ deletable, theme }) => deletable ? theme.deepRed : theme.grey0};
  }
  &:active {
    transform: scale(0.97);
  }
  &:hover {
    background-color: ${({ noHover, deletable, theme }) =>
      noHover
        ? theme.grey6
        : deletable
          ? theme.pastelYellow
          : theme.white};
    border-color: ${({ noHover, theme }) => noHover ? theme.grey4 : theme.grey2};
  }
  &:hover svg {
    fill: ${({ deletable, theme }) => deletable ? theme.midRed : theme.darkTint};
  }
  &:hover div {
    color: ${({ deletable, theme }) => deletable ? theme.midRed : "inherit"};
`;

const Container = styled.div`
  background-position: center;
  background-size: cover;
  user-select: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  text-align: center;
  padding: 0.65em;
  width: 100%;
  min-width: 6em;
`;
const Span = styled.span`
  display: flex;
  user-select: none;
  justify-content: center;
  align-items: center;
  margin-left: ${({ marginLeft }) => marginLeft ? "0.5em" : "0"};
`;
const Text = styled.span`
  margin: 0;
  padding: 0.25em 0;
  color: ${({ theme }) => theme.grey1};
  user-select: none;
  width: max-content;
  max-width: 20em;
  text-align: left;
`;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  padding-left: 0.5em;
  font-size: 1.5em;
`;

const styles = {
  transition: { transition: "auto" },
};


class Pill extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.name !== nextProps.name ||
      this.state !== nextState ||
      this.props.showTagging !== nextProps.showTagging
    );
  }

  render() {
    const { name, nameSuffix, source, img, icon, route, id, tab, staticPill } = this.props;
    const isDeletable = this.props.showTagging && this.props.viewer && !staticPill;
    const hasImgSrc = !!(img && source);

    return (
      <Wrapper
        stretch={this.props.stretch}
        staticPill={staticPill}
        cursor={this.props.cursor}
        noHover={this.props.noHover}
        deletable={isDeletable || this.props.isDisabled}
      >
        {isDeletable || staticPill ? (
          <Container
            deletable={isDeletable}
            onClick={this.props.clickAction}
          >
            <Span>
              {(hasImgSrc || !!name && !staticPill) && (
                <Img
                  draggable="false"
                  src={getImageSource(this.props)}
                />
              )}
            </Span>
            <Text>
              {name}
              {nameSuffix}
            </Text>
            {!staticPill ? (
              <Span marginLeft>
                <FaTimes color={this.props.theme.deepRed}/>
              </Span>
            ) : !!icon && (
              <IconContainer>{icon}</IconContainer>
            )}
          </Container>
        ) : (
          <Link
            to={this.props.linkTo || `/lp/${route}/${id}/${tab || "feed"}`}
            onClick={this.props.clickAction}
            style={styles.transition}
            target={this.props.embedded ? "_blank" : undefined}
            rel={this.props.embedded ? "noreferrer noopener" : undefined}
          >
            <Container>
              {!this.props.noImg && (
                <Span>
                  {(hasImgSrc || !!name) && (
                    <Img
                      draggable="false"
                      src={getImageSource(this.props)}
                      border={hasImgSrc && route === "country"}
                    />
                  )}
                </Span>
              )}
              <Text>
                {name}
                {nameSuffix}
              </Text>
            </Container>
          </Link>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  showTagging: state.showTagging,
});

export default withTheme(connect(mapStateToProps, null)(Pill));
