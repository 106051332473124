import { Link } from "react-router-dom";
import { PureComponent } from "react";

import AltCTA from "components/buttons/alternate/index";
import { FaFilter } from "react-icons/fa";
import MainCTA from "components/buttons/main/index";
import Rule from "./rule/index";
import { history } from "@redux/store";
import { connect } from "react-redux";
import { media } from "constants/responsive/index";
import styled from "styled-components";
import { hideModal } from "@redux/slices/modalSlice";

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  text-align: center;
  [data-tooltipped] {
    margin: 0 0.25em;
  }
  .textFilterContainer,
  .mapFilterContainer {
    width: 100%;
    display: flex;
  }
  .filter-dropdown {
    display: flex;
    flex-wrap: wrap;
    padding: 1em 0;
  }
`;
const AddRule = styled.div`
  animation: fadeIn 1s;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1em;
`;
const ButtonContainer = styled.div`
  min-width: 15em;
  width: 100%;
  padding: 1em;
  display: flex;
  background: ${(props) => props.theme.grey6};
  justify-content: center;
`;
const Section = styled.div`
  animation: fadeIn 1s;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  transition: ${(props) => props.theme.defaultTransition};
  ${media.mobile`
    font-size: 0.8em;
  `};
`;
const H3 = styled.h3`
  color: ${(props) => props.theme.grey2};
  margin: 0;
  width: ${(props) => (props.width ? props.width : "auto")};
  font-weight: bold;
  padding: 0 0.5em;
  display: flex;
  text-transform: uppercase;
  text-align: ${(props) => (props.center ? "center" : "left")};
  align-items: center;
  justify-content: center;
`;
const Hr = styled.hr`
  margin: 0.5em 1em;
  border-color: ${(props) => props.theme.grey5};
`;

const styles = {
  filter: {
    height: "0.8em",
    width: "0.8em",
  },
};

const filterIcon = <FaFilter style={styles.filter} />;

class FilterRules extends PureComponent {
  constructor(props) {
    super(props);
    this.addRule = this.addRule.bind(this);
    this.showAllPhotos = this.showAllPhotos.bind(this);
  }

  componentDidMount() {
    if (!this.props.dataInitialLoad) {
      this.props.apiCall({
        method: "GET",
        component: "INITIAL_LOAD",
        route: "search/initial_load/",
      });
    }
    if (!this.props.dataFeedSuggestionsMain.last_updated) {
      this.props.apiCall({
        method: "GET",
        component: "FEED_FILTERS_GET_SUGGESTIONS_MAIN",
        version: 2,
        route: "suggestions/interests"
      });
    }
    if (
      this.props.feedFilterRules &&
      this.props.feedFilterRules.new &&
      this.props.feedFilterRules.new.filter.length === 0
    ) {
      this.addRule();
    }
  }

  addRule() {
    if (this.props.automationRules && this.props.automationRules.loaded) {
      return;
    } else {
      this.props.setData({
        type: this.props.alerts
          ? "ADD_EMPTY_ALERT_RULE"
          : this.props.settings
          ? "ADD_EMPTY_AUTOMATION_RULE"
          : "ADD_EMPTY_FILTER_RULE",
      });
    }
  }

  showAllPhotos() {
    history.replace({
      pathname: this.props.router.location.pathname,
      search: "",
      state: {},
    });
    this.props.setData({
      type: "FEED_PHOTOS_FILTERS_RESET",
    });
    this.props.hideModal();
  }

  render() {
    return (
      <Wrapper>
        <Section settings={this.props.settings}>
          {this.props.settings &&
          this.props.automationRules &&
          this.props.automationRules.new &&
          this.props.automationRules.new.filter &&
          this.props.automationRules.new.filter.length > 0 ? (
            <AddRule>
              <ButtonContainer>
                <H3 center>AUTOMATICALLY INCLUDE PHOTOS WHERE</H3>
              </ButtonContainer>
            </AddRule>
          ) : null}
          {this.props.settings ? (
            this.props.automationRules &&
            this.props.automationRules.new &&
            this.props.automationRules.new.filter &&
            this.props.automationRules.new.filter.length > 0 &&
            this.props.automationRules.new.filter.map((rule, ruleIndex) => (
              <Rule
                key={`rule-${ruleIndex}`}
                rule={rule}
                ruleIndex={ruleIndex}
                static={this.props.static}
                addRule={this.addRule}
                settings
              />
            ))
          ) : this.props.data ? (
            <Rule
              rule={this.props.data}
              ruleIndex={0}
              static={this.props.static}
              addRule={this.addRule}
              center
              alerts
              settings
            />
          ) : (
            this.props.feedFilterRules.new.filter.map((rule, ruleIndex) => (
              <Rule
                key={`rule-${ruleIndex}`}
                rule={rule}
                ruleIndex={ruleIndex}
                addRule={this.addRule}
              />
            ))
          )}
          {this.props.static || this.props.alerts ? null : this.props
              .settings && this.props.automationRules ? (
            <AddRule>
              <Hr />
              <ButtonContainer>
                {this.props.automationRules.new &&
                this.props.automationRules.new.filter &&
                this.props.automationRules.new.filter.length > 6 ? null : (
                  <AltCTA
                    fullWidth
                    type="button"
                    icon={
                      this.props.automationRules.new &&
                      this.props.automationRules.new.filter &&
                      this.props.automationRules.new.filter.length == 0
                        ? filterIcon
                        : null
                    }
                    text={
                      this.props.automationRules.noRules ||
                      (this.props.automationRules.new &&
                        this.props.automationRules.new.filter &&
                        this.props.automationRules.new.filter.length == 0)
                        ? "Add automation rule"
                        : "OR"
                    }
                    clickAction={this.addRule}
                  />
                )}
              </ButtonContainer>
              <Hr />
            </AddRule>
          ) : this.props.alerts || this.props.data ? null : this.props
              .feedFilterRules.new.filter &&
            this.props.feedFilterRules.new.filter.length > 7 ? null : this.props
              .feedFilterRules.new.filter.length == 0 ? (
            <ButtonContainer>
              <MainCTA
                width="17em"
                text={this.props.translations["18"] || "Add a filter"}
                type="button"
                blue
                clickAction={this.addRule}
                icon={filterIcon}
              />
              {this.props.feedFilterRules.existing.filter.length > 0 &&
                this.props.feedFilterRules.new.filter.length === 0 && (
                  <>
                    <H3>OR</H3>
                    {this.props.dataLandingPageAbout &&
                    this.props.dataLandingPageAbout.id ? (
                      <Link to="/feed">
                        <MainCTA text="Show all photos" width="17em" />
                      </Link>
                    ) : (
                      <MainCTA
                        text="Show all photos"
                        width="17em"
                        clickAction={this.showAllPhotos}
                      />
                    )}
                  </>
                )}
            </ButtonContainer>
          ) : null}
        </Section>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  userPreferences: state.userPreferences,
  automationRules: state.automationRules,
  dataInitialLoad: state.dataInitialLoad,
  dataFeedSuggestionsMain: state.dataFeedSuggestionsMain,
  dataLandingPageAbout: state.dataLandingPageAbout,
  feedFilterRules: state.feedFilterRules,
  router: state.router,
  translations: state.translations,
});

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) => dispatch({ type: arg.type, data: arg.data }),
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
  hideModal: () => dispatch(hideModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterRules);
